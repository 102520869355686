import { createSelector } from "reselect"
import { RootState } from "core/store/configureStore"

import { flipTooth } from "@dentalxrai/transform-landmark-to-svg"
import { ToothWithDetection } from "../types/uploadTypes"

export const getPendingImage = (state: RootState) => state.upload.pending
export const getPreloaderImage = (state: RootState) => state.upload.preloader
export const getDateOfBirth = (state: RootState) => state.upload.dateOfBirth
export const getImageDate = (state: RootState) => state.upload.imageDate
export const getPatientName = (state: RootState) => state.upload.patientName
export const getPatientID = (state: RootState) => state.upload.patientID
export const getStatusFilter = (state: RootState) => state.upload.statusFilter
const getDataImage = (state: RootState) => state.upload.dataImage
export const getShowButton = (state: RootState) => state.upload.showButton
export const getDemoMode = (state: RootState) => state.upload.demoMode
const getNewDataTooth = (state: RootState) => state.upload.newDataTooth
const getFlippedStatus = (state: RootState) => state.upload.isFlipped
export const getImageSizeErrorStatus = (state: RootState) =>
  state.upload.imageSizeError
export const getReportProblemId = (state: RootState) =>
  state.upload.reportProblemId
export const getShouldTakeScreenshot = (state: RootState) =>
  state.upload.shouldTakeScreenshot
export const getScreenshot = (state: RootState) => state.upload.screenshot
export const getPermissionErrorStatus = (state: RootState) =>
  state.upload.isPermissionError
export const getHighSensitivity = (state: RootState) =>
  state.upload.highSensitivity
export const getHighSensitivityAvailable = (state: RootState) =>
  state.upload.highSensitivityAvailable
export const getCurrentBatchUpload = (state: RootState) =>
  state.upload.currentBatchUpload
export const getUploadFiles = (state: RootState) => state.upload.uploadFiles
export const getRejectedFiles = (state: RootState) =>
  state.upload.uploadFiles.rejectedFiles
export const getAcceptedFiles = (state: RootState) =>
  state.upload.uploadFiles.acceptedFiles
export const getConflictedFiles = (state: RootState) =>
  state.upload.uploadFiles.conflictedFiles

export const getDataImageFilter = createSelector(
  getDataImage,
  getNewDataTooth,
  getFlippedStatus,
  (data, newData, isFlipped) => {
    const allTooth: number[] = []
    Object.values(data)
      .filter(Array.isArray)
      .forEach((value) => {
        value.forEach((tooth: { toothName: number }) => {
          const { toothName } = tooth
          if (allTooth.indexOf(toothName) === -1) {
            allTooth.push(toothName)
          }
        })
      })
    if (newData.length > 0) {
      newData.forEach((item: ToothWithDetection) => {
        if (allTooth.indexOf(Number(item.toothName)) === -1) {
          if (item.toothDelete) {
            if (
              item.fillings ||
              item.bridges ||
              item.roots ||
              item.implants ||
              item.crowns ||
              item.bone ||
              item.caries ||
              item.apical
            ) {
              allTooth.push(Number(item.toothName))
            }
          }
          if (!item.toothDelete) {
            allTooth.push(Number(item.toothName))
          }
        } else if (item.toothDelete) {
          allTooth.splice(allTooth.indexOf(Number(item.toothName)), 1)
        }
      })
    }

    return isFlipped
      ? allTooth.map((tooth: number) => flipTooth(tooth))
      : allTooth
  }
)

export const getIsBatchUploadComplete = createSelector(
  getCurrentBatchUpload,
  (currentBatchUpload) => {
    const totalLength: number = currentBatchUpload.total?.length || 0
    const successLength: number = currentBatchUpload.success?.length || 0
    const errorLength: number = currentBatchUpload.error?.length || 0
    return totalLength === successLength + errorLength
  }
)
