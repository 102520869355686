import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/adjustments"
import { AllAnnotations, MoveAnnotation } from "../types/adjustmentTypes"

type AdjustmentsState = Readonly<{
  movingAnnotation: AllAnnotations
  teethAreShifting: boolean
  shiftingTeeth: number[]
  movingStack: MoveAnnotation
  stack: any
}>

export const initialAdjustmentsState: AdjustmentsState = {
  movingAnnotation: AllAnnotations.none,
  teethAreShifting: false,
  shiftingTeeth: [],
  movingStack: {},
  stack: {},
}

type AdjustmentsActions = ActionType<typeof actions>

export default (
  state = initialAdjustmentsState,
  action: AdjustmentsActions
): AdjustmentsState => {
  switch (action.type) {
    case getType(actions.adjustAnnotationsSuccess):
      const stack = action.payload

      return { ...state, stack }

    case getType(actions.moveAnnotationSuccess):
      const movingAnnotation = action.payload

      return { ...state, movingAnnotation }

    case getType(actions.moveAnnotationToSuccess):
      const movingStack = action.payload

      return { ...state, movingStack }

    case getType(actions.startExpandingSuccess):
      const toothId = action.payload

      return { ...state, shiftingTeeth: [toothId] }

    case getType(actions.expandShiftingToSuccess):
      const shiftingTeeth = action.payload

      return { ...state, shiftingTeeth }

    case getType(actions.setNextActiveStackSuccess):
      const nextShiftedTeeth = action.payload

      return { ...state, shiftingTeeth: nextShiftedTeeth }

    case getType(actions.setInitialState):
      return { ...initialAdjustmentsState }

    case getType(actions.toggleTeethAreShifting):
      return {
        ...state,
        teethAreShifting: !state.teethAreShifting,
      }

    default:
      return state
  }
}
