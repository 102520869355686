import { FmxTemplate, RadiographSet } from "library/common/types/patientTypes"

export const updatedRadiographSetPositions = (
  activeRadiographSet: RadiographSet | null
) => {
  const radiographs = activeRadiographSet?.radiographs || []
  const changes = activeRadiographSet?.changes || []
  const changesMap = new Map(changes.map((item) => [item.resultId, item]))

  const radiographPositions = radiographs.map((resultId, i) => {
    if (changesMap.has(resultId)) {
      return { position: changesMap.get(resultId)?.position || 0, resultId }
    }
    return { position: i, resultId }
  })

  return radiographPositions
}

// Get the number value from the template (fmx-14 -> 14)
export const templateAmount = (template: FmxTemplate) =>
  Number(template.split("-")[1] || 0)

export const isRadiographInUpperJaw = (position: number, template: number) =>
  position === 0 || (position ? position <= template / 2 - 1 : false)

export const isVerticalBlock = (templateValue: number, index: number) =>
  (index > 1 && index < 5) ||
  (templateValue === 14 ? index > 8 && index < 12 : index > 12 && index < 16)
