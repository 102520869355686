import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"
import differenceInDays from "date-fns/differenceInDays"

import * as entitiesSelector from "library/common/selectors/entities"
import { isHighSensitivityMode } from "library/utilities/filterSensAnnotations"
import {
  getActiveNames,
  getIsEnabled,
  getShowNerveCanal,
} from "library/common/selectors/filters"
import {
  getCariesPro,
  getBonelossPro,
  getAnnotationsShown,
  getIsHighSenseModeActive,
} from "library/common/selectors/image"
import { parseHsmData } from "library/utilities/hsm"
import { getTeethTemplate } from "library/common/selectors/teeth"
import { getBoneloss } from "library/common/selectors/entities"
import {
  changedBonelossLandmarks,
  transformLandmark,
  applyMovedTeeth,
  getNextTooth,
} from "@dentalxrai/transform-landmark-to-svg"

import {
  BBoxPosition,
  Detection,
  UserChange,
} from "library/common/types/dataStructureTypes"
import {
  AnnotationRecord,
  AnnotationToRender,
  Features,
  INote,
  ITooth,
  Kind,
  ToothMapPosition,
  ToothSegmentInfo,
  sortedINote,
} from "library/common/types/serverDataTypes"
import {
  AnnotationName,
  BonelossChange,
  CariesDepth,
  Landmark,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"
import * as annotationsUtils from "library/utilities/annotations"
import {
  getLicense,
  getShowBoneLossLite,
  getLicenceExpire,
  getCalculus,
} from "./user"
import { License } from "../types/userTypes"
import { boundingBox } from "pages/Dashboard/Frames/XRayImage/utils/mathUtils"
import { CARIES_OPACITY, DISABLING_CASES } from "library/utilities/constants"
import {
  bonelossPercentage,
  crownOrBridgeOverride,
} from "../../utilities/tooth"
import {
  getIsLargerAspectRatioScreen,
  getIsSmallScreen,
  getShowDynamicPbl,
} from "./imageControls"
import { releaseCanvas } from "library/utilities/releaseCanvas"
import { getDataIsChanged } from "./saving"
import { groupBy } from "library/utilities/groupBy"
import { transformDate } from "library/utilities/date"
import { isTouchDevice } from "library/utilities/browser"
import { getFeatureNerveCanal } from "./features"

export interface IMatchedTooth {
  tooth: number
  isDetected: boolean
}

export type ImageLayerData = Readonly<{
  id: number
  name?: string
  imageUrl: string
  visible: boolean
  opacity: number | undefined
  addedSubtype?: string
  bbox?: BBoxPosition
}>

export type ActiveToothImageLayerData = Readonly<{
  id: number
  imageUrl: string
  x: number
  y: number
  width: number
  height: number
}>

export const getAllUserChanges = (state: RootState) =>
  state.serverData.present.changes
export const getAllAdditions = (state: RootState) =>
  state.serverData.present.additions
export const getAllAddedTeeth = (state: RootState) =>
  state.serverData.present.addedTeeth
export const getAllRemovedTeeth = (state: RootState) =>
  state.serverData.present.removedTeeth
export const getMovedTeeth = (state: RootState) =>
  state.serverData.present.movedTeeth

export const getActiveTooth = (state: RootState) => state.teeth.activeTooth

export const getGeneralComment = (state: RootState) =>
  state.serverData.present.generalComment
export const getAddedComments = (state: RootState) =>
  state.serverData.present.addedComments

export const getNotes = (state: RootState) => state.serverData.present.notes

export const getBoneLossForm = (state: RootState) =>
  state.serverData.present.forms.boneLoss

export const getChangedBoneLossTeeth = (state: RootState) =>
  state.serverData.present.forms.boneLoss.changedTeeth

export const getDisabledAnnotations = createSelector(
  [getAllUserChanges],
  (changes) =>
    changes
      .filter((change) => change.action === "rejected")
      .map((change) => change.annotationId)
)

// Image filters lives here to allow undo/redo of stack
export const getBrightness = (state: RootState) =>
  state.serverData.present.imageFilters.brightness
export const getContrast = (state: RootState) =>
  state.serverData.present.imageFilters.contrast
export const getSaturation = (state: RootState) =>
  state.serverData.present.imageFilters.saturation

const snap = (value: number, center: number, delta: number) =>
  Math.abs(value - center) < delta ? center : value
export const getBrightnessSnapped = (state: RootState) =>
  snap(state.serverData.present.imageFilters.brightness, 1, 0.1)
export const getContrastSnapped = (state: RootState) =>
  snap(state.serverData.present.imageFilters.contrast, 0, 0.07)
export const getSaturationSnapped = (state: RootState) =>
  snap(state.serverData.present.imageFilters.saturation, 0.5, 0.05)

// Selectors for imageMeta.
export const getImageMeta = (state: RootState) =>
  state.serverData.present.imageMeta
export const getImageMetaWarnings = (state: RootState) =>
  state.serverData.present.imageMeta.warnings
export const getImageRotation = (state: RootState) =>
  state.serverData.present.imageMeta.angleImageRotation
export const getPatientID = (state: RootState) =>
  state.serverData.present.imageMeta.patientID
export const getPatientName = (state: RootState) =>
  state.serverData.present.imageMeta.patientName
export const getDateOfBirth = (state: RootState) =>
  state.serverData.present.imageMeta.dateOfBirth
export const getImageDate = (state: RootState) =>
  state.serverData.present.imageMeta.imageDate
export const getAnalysisDate = (state: RootState) =>
  state.serverData.present.imageMeta.analysisDate
export const getIsOutdatedAnalysis = (state: RootState) =>
  state.serverData.present.imageMeta.outdatedAnalysis
export const getFileName = (state: RootState) =>
  state.serverData.present.imageMeta.fileName
export const getKind = (state: RootState) =>
  state.serverData.present.imageMeta.kind
export const getIsImageHorizontallyFlipped = (state: RootState) =>
  state.serverData.present.imageMeta.isImageHorizontallyFlipped
export const getDisplayHorizontallyFlipped = (state: RootState) =>
  !!state.serverData.present.imageMeta.displayHorizontallyFlipped
export const getIsOwner = (state: RootState) =>
  state.serverData.present.imageMeta.isOwner
export const getFeatures = (state: RootState) =>
  state.serverData.present.imageMeta.features
export const getActiveAddition = (state: RootState) =>
  state.drawing.activeAddition
export const getPatientUuid = (state: RootState) =>
  state.serverData.present.imageMeta.patientUuid

export const getMissingMetadata = (state: RootState) =>
  state.serverData.present.imageMeta.missingMetadata
export const getOrthoConditionNotification = (state: RootState) =>
  state.features.featureShowOrthoWarning &&
  state.serverData.present.imageMeta.warnings?.orthoCondition
export const getToothBasedPeri = createSelector(
  [getFeatures],
  (features) => !!features?.includes(Features.ToothBasedPeri)
)
export const getCroppedPanNotification = createSelector(
  [getImageMetaWarnings, getKind],
  (warnings, kind) => warnings?.partiallyShown && kind === Kind.Opg
)

export const getShowLetterBasedPeri = createSelector(
  [getKind, getToothBasedPeri],
  (kind, toothBasedPeri) => kind === Kind.Peri && !toothBasedPeri
)

export const getIsBwOrToothBasedPeri = createSelector(
  [getKind, getToothBasedPeri],
  (kind, toothBasedPeri) =>
    kind === Kind.Bw || (kind === Kind.Peri && toothBasedPeri)
)

export const getToothMapPosition = createSelector(
  [
    getIsLargerAspectRatioScreen,
    getBonelossPro,
    getIsSmallScreen,
    getKind,
    getActiveTooth,
  ],
  (
    isLargerAspectRatioScreen,
    bonelossPro,
    isSmallScreen,
    kind,
    activeTooth
  ) => {
    // right position is hidden for small screens when we have an active tooth
    const right =
      activeTooth && isSmallScreen
        ? ToothMapPosition.None
        : ToothMapPosition.RightBar
    switch (kind) {
      case Kind.Opg:
        return bonelossPro || !isLargerAspectRatioScreen
          ? ToothMapPosition.BelowXray
          : right
      case Kind.Bw:
        return isLargerAspectRatioScreen ? ToothMapPosition.BesideXray : right
      case Kind.Peri:
        return ToothMapPosition.RightBar
      default:
        return ToothMapPosition.None
    }
  }
)

export const getTeethToRender = createSelector(
  [entitiesSelector.getDetectedTeeth, getAllAddedTeeth, getAllRemovedTeeth],
  (detectedTeeth, addedTeeth, removedTeeth) => {
    const removedIds = removedTeeth.map((tooth) => tooth.toothName)
    const teethToRender = detectedTeeth.filter(
      (tooth) => !removedIds.includes(tooth.toothName)
    )

    return teethToRender.concat(addedTeeth)
  }
)

// All AI detections, but also considering moved teeth.
export const getDetectionsToRenderForAllTeeth = createSelector(
  [entitiesSelector.getDetections, getAllUserChanges, getIsHighSenseModeActive],
  (allDetections, allUserChanges, isHighSensitivityModeActive) => {
    const acceptedHSMIds = allUserChanges
      .filter((change) => change.action === "accepted" && change.isHSM)
      .map((change) => change.annotationId)

    const activeDetections: Detection[] = allDetections.filter(
      (detection) =>
        isHighSensitivityModeActive ||
        !isHighSensitivityMode(detection.subtype) ||
        acceptedHSMIds.includes(detection.id)
    )

    const movedChange = allUserChanges.filter((change) => change.newTooth)
    movedChange.forEach((toMove) => {
      const found = activeDetections.findIndex(
        (candidate) => candidate.id === toMove.annotationId
      )
      if (found !== -1) {
        activeDetections[found] = {
          ...activeDetections[found],
          toothName: toMove.newTooth ?? -1,
        }
      }
    })

    return activeDetections
  }
)

/**
 * Returns detections mapped by `general` subtypes. General subtypes means that different caries subtypes
 * will be included in its parent subtype.
 * E.g.: "caries" is the general subtype, but "caries_HSM" is another variation,
 * which will be included as part of the "caries" annotations.
 */
export const getDetectionsForActiveToothBySubtype = createSelector(
  [getDetectionsToRenderForAllTeeth, getActiveTooth],
  (detections, activeTooth) => {
    const toothDetections = detections.filter(
      (detection) => detection.toothName === activeTooth
    )
    const subtypes = [
      AnnotationName.caries,
      AnnotationName.apical,
      RestorationSubtype.bridges,
      RestorationSubtype.crowns,
      RestorationSubtype.fillings,
      RestorationSubtype.implants,
      RestorationSubtype.roots,
      AnnotationName.calculus,
      AnnotationName.nervus,
      AnnotationName.impacted,
    ]
    const detectionsMappedBySubtype: Record<string, Detection[]> = {}
    subtypes.forEach(
      (sub) =>
        (detectionsMappedBySubtype[sub] = toothDetections.filter(
          (detection) =>
            // => check function `isHighSensitivityMode` for accepted hsm subtypes
            detection.subtype === sub ||
            detection.subtype === `${sub}_HSM` ||
            detection.subtype === `${sub}_F2` ||
            detection.subtype === `${sub}_F3`
        ))
    )
    return detectionsMappedBySubtype
  }
)

export const getActiveToothExists = createSelector(
  [
    getActiveTooth,
    entitiesSelector.getDetectedTeeth,
    getAllAddedTeeth,
    getAllRemovedTeeth,
  ],
  (activeTooth, detectedTeeth, addedTeeth, removedTeeth) => {
    const toothExists = (tooth: number) =>
      addedTeeth.some((candidate) => candidate.toothName === tooth) ||
      (detectedTeeth.some((candidate) => candidate.toothName === tooth) &&
        !removedTeeth.some((candidate) => candidate.toothName === tooth))

    // activeTooth can be null, otherwise return a boolean
    if (!activeTooth) return null
    else return toothExists(activeTooth)
  }
)

export const getAnnotationsToRenderForAllTeeth = createSelector(
  [
    getDetectionsToRenderForAllTeeth,
    getAllAdditions,
    getAllUserChanges,
    getCariesPro,
    entitiesSelector.getDetectionVisibility,
    getIsHighSenseModeActive,
  ],
  (
    allDetections,
    additions,
    allUserChanges,
    cariesPro,
    visibleEntities,
    isHighSenseModeActive
  ) => {
    const hsmData = parseHsmData(
      allDetections,
      allUserChanges,
      visibleEntities,
      cariesPro,
      isHighSenseModeActive
    )
    const changedAnnotations = new Map<number, UserChange>()
    allUserChanges
      .filter((change) => change.action === "changed")
      .forEach((change) => changedAnnotations.set(change.annotationId, change))

    const drawingRejects = additions.flatMap((a) => a.rejectedIds || [])
    const annotations: AnnotationToRender[] = allDetections
      .filter((a) => !drawingRejects.includes(a.id))
      .map(
        ({
          id,
          toothName,
          subtype,
          location,
          depth,
          center_mass_x,
          center_mass_y,
          replacing,
        }) => {
          const type = annotationsUtils.getAnnotationNameType(subtype)
          const isHSM = !!isHighSensitivityMode(subtype)
          const changed = changedAnnotations.get(id)
          const locationValue =
            toothName % 10 < 4
              ? changed?.location?.replace("o", "i")
              : changed?.location?.replace("i", "o")
          return {
            id,
            toothName,
            type,
            location:
              cariesPro || type === AnnotationName.calculus // show location only for caries pro or calculus
                ? changed?.location
                  ? locationValue
                  : changed?.location ?? location
                : "",

            depth: cariesPro ? changed?.depth ?? depth : CariesDepth.Unknown, // hide depth when caries pro is not active
            subtype: annotationsUtils.getRestorationSubtype(subtype),
            shouldMakeActive: !isHSM || hsmData.accepted.includes(id),
            isAddition: false,
            // HSM:
            isHSM,
            isAcceptedHSM: hsmData.accepted.includes(id),
            isRejectedHSM: hsmData.rejected.includes(id),
            isUnconfirmedHSM: hsmData.unconfirmed.includes(id),
            isEnlargementHSM: hsmData.enlargements.includes(id),
            isDisplayableHSM: hsmData.hsmIdsToDisplay.includes(id),
            center_mass_x,
            center_mass_y,
            replacing,
          }
        }
      )

    const allAnnotations: AnnotationToRender[] = annotations.concat(
      additions.map((annot) => ({
        ...annot,
        location:
          cariesPro || annot.type === AnnotationName.calculus
            ? annot.location
            : "", // hide location / depth info outside of caries pro
        depth: cariesPro ? annot.depth : CariesDepth.Unknown,
        shouldMakeActive: true,
        isAddition: true,
        // HSM:
        isHSM: false,
        isAcceptedHSM: false,
        isRejectedHSM: false,
        isUnconfirmedHSM: false,
        isEnlargementHSM: false,
        isDisplayableHSM: false,
      }))
    )
    return allAnnotations
  }
)

export const getAnnotationsToRenderForActiveTooth = createSelector(
  [getAnnotationsToRenderForAllTeeth, getActiveTooth, getCariesPro],
  (allAnnotations, activeTooth, cariesPro) => {
    const toothAnnotations = allAnnotations.filter(
      (a) => a.toothName === activeTooth
    )
    return cariesPro
      ? // when caries pro is enabled we don't display duplicated HSM annotations already existing
        toothAnnotations.filter(
          (a) => !a.isEnlargementHSM || a.isAcceptedHSM || a.isRejectedHSM
        )
      : toothAnnotations
  }
)

export const isHSMAvailable = createSelector(
  [entitiesSelector.getDetections],
  (detections) => {
    return (
      detections.some(
        (d) => d.subtype === "caries_HSM" || d.subtype === "apical_HSM"
      ) ||
      !detections.some(
        (d) =>
          d.subtype == AnnotationName.caries ||
          d.subtype == AnnotationName.apical
      )
    )
  }
)

/**
 convert number to letter (1 => A, 2 => B, ...)
 Returns only capital letters and when characters used up
 it concatenates its product such as X,Y,Z,AA,AB.
 Supports up to double letters only. Max is 702 => ZZ.
 */
const toLetter = (detectionNumber: number) => {
  if (detectionNumber < 26) {
    return String.fromCharCode(detectionNumber + 65)
  } else {
    const div = Math.floor(detectionNumber / 26)
    const mod = detectionNumber % 26
    return String.fromCharCode(64 + div) + String.fromCharCode(65 + mod)
  }
}

/** Sorts a list of objects based on the object's value of a key pass as an argument. */
const sortByKey = (
  objs: AnnotationToRender[],
  key: keyof AnnotationToRender
) => {
  return objs.sort((a, b) => (a[key] as any) - (b[key] as any))
}

/**
 * Use this to know if the image ONLY (no toothmap) should be displayed horizontally flipped.
 *   This considers both flipping flags on the system. `isImageHorizontallyFlipped` and `displayHorizontallyFlipped`
 */
export const getDisplayImageFlipped = createSelector(
  [getIsImageHorizontallyFlipped, getDisplayHorizontallyFlipped],
  (isImageHorizontallyFlipped, displayHorizontallyFlipped) =>
    isImageHorizontallyFlipped != displayHorizontallyFlipped
)

const getTeethListUnfiltered = createSelector(
  [
    getTeethTemplate,
    getTeethToRender,
    getAnnotationsToRenderForAllTeeth,
    getAddedComments,
    getCariesPro,
    getDisplayImageFlipped,
    getCalculus,
    getShowLetterBasedPeri,
  ],
  (
    template,
    teethToRender,
    allAnnotations,
    addedComments,
    cariesPro,
    displayImageFlipped,
    calculus,
    showLetterBasedPeri
  ): Record<string, ITooth[]> | any[] => {
    if (showLetterBasedPeri) {
      // split predicted from user added annotations.
      const predicted = allAnnotations.filter((a) => !!a.center_mass_x)
      const added = allAnnotations.filter(
        (a) => !a.center_mass_x && a.type !== AnnotationName.annotate
      )
      // sort and flip only the predicted as they are the only ones who have center_mass_x
      let sorted = sortByKey(predicted, "center_mass_x")
      if (displayImageFlipped) {
        sorted = sorted.reverse()
      }
      // put HSM annotations last
      // TODO: ensure consistent letters for HSM annotations after acceptance
      sorted = sorted.sort((a, b) => +a.isHSM - +b.isHSM)
      return sorted.concat(added).map((a, key) => ({
        ...a,
        toothName: toLetter(key), // Replace toothName with letter of detection
      }))
    }

    const activeTeeth = new Set(teethToRender?.map((t) => t.toothName))
    const commentedTeeth = new Set(addedComments.map((t) => t.toothName))

    return Object.entries(template).reduce(
      (side: Record<string, any>, [key, value]) => (
        (side[key] = value.map((templateTooth: number) => {
          const isDetected = activeTeeth.has(templateTooth)
          const hasComment = commentedTeeth.has(templateTooth)
          const detectionsForTooth = allAnnotations.filter(
            (annotation) => annotation.toothName === templateTooth
          )

          const filterAnnotationsByType = (type: string) => {
            const annotations = detectionsForTooth.filter((a) => a.type == type)
            return cariesPro
              ? // when caries pro is enabled we don't display duplicated HSM annotations already existing
                annotations.filter(
                  (a) =>
                    !a.isEnlargementHSM || a.isAcceptedHSM || a.isRejectedHSM
                )
              : annotations
          }

          return {
            tooth: templateTooth,
            isDetected,
            hasComment,
            annotations: {
              caries: filterAnnotationsByType(AnnotationName.caries),
              apical: filterAnnotationsByType(AnnotationName.apical),
              restorations: filterAnnotationsByType(
                AnnotationName.restorations
              ),
              calculus:
                calculus && filterAnnotationsByType(AnnotationName.calculus),
              nervus: filterAnnotationsByType(AnnotationName.nervus),
              impacted: filterAnnotationsByType(AnnotationName.impacted),
            },
          }
        })),
        side
      ),
      {}
    )
  }
)

const getTeethList = createSelector(
  [
    getTeethTemplate,
    getTeethToRender,
    getAnnotationsToRenderForAllTeeth,
    getDisabledAnnotations,
    getAddedComments,
    getCalculus,
  ],
  (
    template,
    teethToRender,
    allAnnotations,
    disabledAnnotations,
    addedComments,
    calculus
  ) => {
    // TODO (Tim): Rewrite RightTeethmap Component to use direct values instead of formatting.
    const filteredAnnotations = allAnnotations.filter(
      ({ id }) => !disabledAnnotations.includes(id ?? 0)
    )
    const activeTeeth = new Set(teethToRender?.map((t) => t.toothName))
    const commentedTeeth = new Set(addedComments.map((t) => t.toothName))
    const filteredTeethList: Record<string, ITooth[]> = {}
    Object.entries(template).forEach(([key, teeth]) => {
      filteredTeethList[key] = teeth.map((templateTooth: number): ITooth => {
        const isDetected = activeTeeth.has(templateTooth)
        const hasComment = commentedTeeth.has(templateTooth)
        const detectionsForTooth = filteredAnnotations.filter(
          (annotation) => annotation.toothName === templateTooth
        )

        return {
          tooth: templateTooth,
          isDetected,
          hasComment,
          annotations: {
            caries: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.caries
            ),
            apical: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.apical
            ),
            restorations: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.restorations
            ),
            calculus: detectionsForTooth.filter(
              (annotation) =>
                // only populate with calculus if user has permission
                calculus && annotation.type === AnnotationName.calculus
            ),
            nervus: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.nervus
            ),
            impacted: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.impacted
            ),
          },
        }
      })
    })

    return filteredTeethList
  }
)

export const getTeethListForJawTeethMap = createSelector(
  [getTeethList],
  (fullTeethList) => {
    const newToothList: Record<string, ITooth[]> = {}
    Object.entries(fullTeethList).forEach(([key, value]) => {
      newToothList[key] = value.map((entry): ITooth => {
        // Filter teeth with no annotation to prohibit rendering these.
        const { annotations, hasComment } = entry
        if (
          hasComment ||
          Object.values(annotations || {}).some((a) => a.length)
        ) {
          return entry
        } else {
          // TODO (Tim): JawTeethMap currently expects annotations to be null. Change jawteethmap to match the new data caries.length>0...
          return { ...entry, annotations: null }
        }
      })
    })
    return newToothList
  }
)

export const getTeethListForRightTeethMap = createSelector(
  [getTeethListUnfiltered, getShowLetterBasedPeri],
  (teethList, showLetterBasedPeri) => {
    if (showLetterBasedPeri) {
      return teethList
    }

    const result: Record<string, ITooth[]> = {}
    const keys = ["upLeft", "upRight", "downRight", "downLeft"]

    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[key] = teethList[key]?.filter((entry: ITooth) => {
        // Filter teeth with no annotation to prohibit rendering these.
        const { annotations, hasComment } = entry
        return (
          hasComment || Object.values(annotations || {}).some((a) => a.length)
        )
      })
      // in the detection list we go right to left on the bottom teeth:
      if (key.startsWith("down")) result[key]?.reverse()
    })

    return result
  }
)

export const annotationsDataForTooth = createSelector(
  // TODO (Tim): rewrite component to use getAnnotationsToRenderForActiveTooth instead.
  [getAnnotationsToRenderForActiveTooth, getActiveTooth, getCalculus],
  (activeAnnotations, activeTooth, calculus) => {
    if (activeTooth === null) {
      return {
        tooth: -1,
        annotations: {
          caries: [],
          apical: [],
          restorations: [],
          calculus: [],
          nervus: [],
          impacted: [],
        },
      }
    }

    const annotations: AnnotationRecord = {}
    const annotationNames = [
      AnnotationName.caries,
      AnnotationName.apical,
      AnnotationName.calculus,
      AnnotationName.restorations,
      AnnotationName.nervus,
      AnnotationName.impacted,
    ]
    annotationNames.forEach(
      (type) =>
        (annotations[type] = activeAnnotations.filter(
          (annotationOnTooth) =>
            annotationOnTooth.type === type &&
            (annotationOnTooth.type !== AnnotationName.calculus || calculus)
        ))
    )

    return {
      tooth: activeTooth,
      annotations,
    }
  }
)

export const filteredAnnotationsDataForTooth = createSelector(
  [annotationsDataForTooth, getDisabledAnnotations],
  ({ tooth, annotations }, disabledAnnotations) => {
    // the same as annotationsDataForTooth, but filtered to exclude rejections
    const filteredAnnotations: AnnotationRecord = {}
    Object.keys(annotations).forEach((key) => {
      const name = key as AnnotationName
      filteredAnnotations[name] =
        annotations[name]?.filter(
          ({ id }: AnnotationToRender) => !disabledAnnotations.includes(id ?? 0)
        ) || []
    })

    return {
      tooth,
      annotations: filteredAnnotations,
    }
  }
)

export const getNextToothMoveTo = createSelector(
  [getActiveTooth, getDisplayHorizontallyFlipped],
  (activeToothId, displayHorizontallyFlipped) => {
    const dir = displayHorizontallyFlipped ? -1 : 1
    return (nextIndex: number, currentToothId?: number | null) =>
      getNextTooth(currentToothId || activeToothId || -1, nextIndex * dir)
  }
)

export const getNextToothNoGaps = createSelector(
  [getTeethToRender, getNextToothMoveTo],
  (detectedTeeth, nextToothMoveTo) => (direction: number) => {
    let current: number | undefined
    const teeth = new Set(detectedTeeth?.map((t) => t.toothName))
    while (true) {
      current = nextToothMoveTo(direction, current)
      if (!current || teeth.has(current)) return current
    }
  }
)

export const getFilteredNerveCanal = createSelector(
  [
    getAnnotationsToRenderForAllTeeth,
    getDisabledAnnotations,
    entitiesSelector.getNerveCanal,
  ],
  (allAnnotations, disabledAnnotations, nerveCanal) => {
    const teethWithNervus = allAnnotations
      .filter(({ id }) => !disabledAnnotations.includes(id ?? 0))
      .flatMap((s) => (s.type === AnnotationName.nervus && s.toothName) || [])

    return nerveCanal[0]?.toothCoordinates?.filter((n) =>
      teethWithNervus.includes(n.toothName)
    )
  }
)

export const getChangedBonelossValues = createSelector(
  [
    getBoneloss,
    getChangedBoneLossTeeth,
    getMovedTeeth,
    getImageMeta,
    getAllAdditions,
    getAllRemovedTeeth,
    getAllUserChanges,
    entitiesSelector.getRestorations,
  ],
  (
    boneloss,
    toothBoneLoss,
    movedTeeth,
    meta,
    allAdditions,
    removedTeeth,
    changes,
    restorations
  ) => {
    // PBL library
    return changedBonelossLandmarks(
      toothBoneLoss,
      movedTeeth,
      meta,
      boneloss,
      allAdditions,
      removedTeeth,
      changes,
      restorations
    )
  }
)

export const getMaxBoneLossTooth = createSelector(
  [getChangedBonelossValues, getTeethToRender],
  (changedBonelossValues, teethToRender) => {
    const values = changedBonelossValues.filter((c) =>
      teethToRender.some((t) => t.toothName === c.toothName)
    )

    const maxBoneLossPercent = Math.max(
      ...values.map((boneLossPerTooth) =>
        Math.max(boneLossPerTooth.d || 0, boneLossPerTooth.m || 0)
      )
    )

    const toothName =
      values.find(
        (c) => c.d === maxBoneLossPercent || c.m === maxBoneLossPercent
      )?.toothName || null

    return {
      maxBoneLossPercent,
      toothName,
    }
  }
)

export const getBoneLossFormValues = createSelector(
  [
    getBoneLossForm,
    getTeethToRender,
    getAnnotationsToRenderForAllTeeth,
    getMaxBoneLossTooth,
    getKind,
  ],
  (boneLossForm, teethToRender, annotations, maxBoneLossTooth, kind) => {
    const maxBoneLossPercent = maxBoneLossTooth.maxBoneLossPercent

    const boneLossIndexValue =
      boneLossForm.age &&
      maxBoneLossPercent &&
      maxBoneLossPercent / boneLossForm.age

    const boneLossIndex = (() => {
      if (!boneLossIndexValue || !boneLossForm.age) {
        return ""
      }
      switch (true) {
        case boneLossIndexValue < 0.25:
          return "<0.25"
        case boneLossIndexValue <= 1:
          return "0.25-1.0"
        default:
          return ">1.0"
      }
    })()

    const boneLossIndexDisplayValue =
      boneLossForm.boneLossIndexOverride || boneLossIndex

    const grade = (() => {
      switch (true) {
        case boneLossIndexDisplayValue === ">1.0" ||
          boneLossForm.diabetes === "II" ||
          boneLossForm.smoking === ">=10":
          return "C"
        case boneLossIndexDisplayValue === "0.25-1.0" ||
          boneLossForm.diabetes === "I" ||
          boneLossForm.smoking === "<10":
          return "B"
        case boneLossIndexDisplayValue === "<0.25" ||
          boneLossForm.diabetes === "-" ||
          boneLossForm.smoking === "-":
          return "A"
        default:
          return "-"
      }
    })()

    // Exclude implants and wisdom teeth
    const ignoredTeeth = annotations
      .filter((a) => a.subtype === RestorationSubtype.implants)
      .map((a) => a.toothName)
      .concat([18, 28, 38, 48])

    const existingTeeth = teethToRender.filter(
      (tooth) => !ignoredTeeth.includes(tooth.toothName)
    )

    // 28 possible non-wisdom teeth
    const numberOfLostTeeth = 28 - existingTeeth.length

    const toothLoss = (() => {
      if (boneLossForm.toothLoss !== "") {
        return boneLossForm.toothLoss
      }
      switch (true) {
        case kind === Kind.Peri:
          return ""
        case numberOfLostTeeth >= 5:
          return ">=5"
        case numberOfLostTeeth > 0:
          return "<=4"
        default:
          return "-"
      }
    })()

    const maxBoneLossPercentCategory = bonelossPercentage(maxBoneLossPercent)

    const bonelossCategory =
      boneLossForm.maxBoneLossPercentCategoryOverride ||
      maxBoneLossPercentCategory

    const stage = (() => {
      switch (true) {
        case toothLoss === ">=5" || boneLossForm.complications === "complex":
          return "IV"
        case bonelossCategory === ">33" ||
          toothLoss === "<=4" ||
          boneLossForm.complications === "ST>=6":
          return "III"
        case bonelossCategory === "15-33" ||
          boneLossForm.complications === "ST=5":
          return "II"
        case bonelossCategory === "<15" || toothLoss === "-":
          return "I"
      }
    })()

    const showComplex =
      bonelossCategory === ">33" || toothLoss === "<=4" || toothLoss === ">=5"
    const showSt6 = showComplex || bonelossCategory === "15-33"

    const draft = !(
      toothLoss &&
      boneLossForm.distribution &&
      boneLossForm.age &&
      boneLossIndexDisplayValue &&
      boneLossForm.diabetes &&
      boneLossForm.smoking
    )

    return {
      ...boneLossForm,
      stage,
      toothLoss,
      maxBoneLossPercent,
      maxBoneLossPercentCategory,
      grade,
      boneLossIndex,
      draft,
      showComplex,
      showSt6,
    }
  }
)

export const getHasHsmAnnotations = createSelector(
  [getAnnotationsToRenderForAllTeeth],
  (allAnnotations) =>
    allAnnotations.some(
      (a) =>
        !a.isEnlargementHSM &&
        (a.isUnconfirmedHSM || a.isAcceptedHSM || a.isRejectedHSM)
    )
)

export const getInferenceStatus = (state: RootState) =>
  state.serverData.present.inferenceStatus

const getToothSegments = createSelector(
  [entitiesSelector.getEntities, getMovedTeeth],
  (entities, movedTeeth) => applyMovedTeeth(entities.segments, movedTeeth)
)

export const getCroppedTeeth = createSelector(
  [entitiesSelector.getEntities, getMovedTeeth],
  ({ croppedTeeth }, movedTeeth) => {
    // reimplementation of "applyMovedTeeth" for cropped teeth:
    if (!movedTeeth || !croppedTeeth) return croppedTeeth
    // update the toothNames to account for movedTeeth
    // Don't include teeth that were overwritten
    const overwrittenTeeth = Object.values(movedTeeth).filter(
      (t) => !movedTeeth[t]
    )
    return Object.fromEntries(
      Object.entries(croppedTeeth)
        .filter(([k]) => !overwrittenTeeth.includes(+k))
        .map(([k, v]) => [movedTeeth[k] || k, v])
    )
  }
)

export const getActiveToothLayers = createSelector(
  [getActiveTooth, getActiveToothExists, getToothSegments],
  (activeTooth, activeToothExists, toothSegments) => {
    if (activeToothExists && activeTooth) {
      const activeToothAnnotation = toothSegments.find(
        (s) => s.toothName == activeTooth
      )
      if (activeToothAnnotation) {
        const { id, mask } = activeToothAnnotation
        return [
          {
            id: id,
            imageUrl: `data:image/png;base64,${mask}`,
            visible: true,
            opacity: 0.5,
            ...boundingBox(activeToothAnnotation),
          },
        ]
      }
    }
    return []
  }
)

const loadUrlAsImage = async (url: string) => {
  if (typeof createImageBitmap === "function") {
    // use createImageBitmap with fetch on supported browsers
    const response = await fetch(url)
    const imgBlob = await response.blob()
    return await createImageBitmap(imgBlob)
  } else {
    // polyfill for older Safari
    const promise = new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image()
      img.src = url
      img.addEventListener("load", () => resolve(img))
      img.addEventListener("error", () => reject("failed to load image"))
    })
    return await promise
  }
}

export const getToothPicker = createSelector(
  [getToothSegments, getImageMeta],
  (toothSegments, meta) => {
    const { imageWidth: width, imageHeight: height } = meta

    // limit the canvas scale in order to reduce the memory consumption of this feature
    const scale = Math.max(width / 500, height / 500, 1)
    const canvasWidth = Math.round(width / scale)
    const canvasHeight = Math.round(height / scale)

    const teeth: ToothSegmentInfo[] = []
    if (toothSegments.length && width && height) {
      const canvas = document.createElement("canvas")
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      const ctx = canvas.getContext("2d", {
        willReadFrequently: true,
      })
      if (ctx) {
        toothSegments.forEach((segment) => {
          const loadImageData = async () => {
            const img = await loadUrlAsImage(
              `data:image/png;base64,${segment.mask}`
            )
            ctx.clearRect(0, 0, canvasWidth, canvasHeight)
            ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight)
            const imageData = ctx.getImageData(
              0,
              0,
              canvasWidth,
              canvasHeight
            ).data
            teeth.push({ imageData, toothName: segment.toothName })

            if (teeth.length == toothSegments.length) releaseCanvas(canvas)
            else ctx.clearRect(0, 0, canvasWidth, canvasHeight)
          }
          loadImageData().catch((err) =>
            console.error("error drawing tooth segment for picker:", err)
          )
        })
      } else {
        releaseCanvas(canvas)
      }
    }

    return (x: number, y: number) => {
      if (x >= 0 && x < width && y >= 0 && y < height) {
        const scaledX = Math.round(x / scale)
        const scaledY = Math.round(y / scale)
        const idx = (scaledY * canvasWidth + scaledX) * 4 + 3
        return teeth.find((t) => t.imageData[idx])?.toothName
      }
    }
  }
)

const getLegacyBonelossLayer = createSelector(
  [getBoneloss, getBonelossPro, getIsEnabled, getShowDynamicPbl],
  (boneloss, bonelossPro, isEnabled, showDynamicPbl) => {
    if (showDynamicPbl || !boneloss || !boneloss.mask) return []
    return {
      id: -1,
      imageUrl: `data:image/png;base64,${boneloss.mask}`,
      visible: !!bonelossPro && isEnabled,
      opacity: undefined,
    }
  }
)

export const getSvgLayer = createSelector(
  [
    getImageMeta,
    getBoneloss,
    getBonelossPro,
    getIsEnabled,
    getAnnotationsShown,
    getShowDynamicPbl,
    getChangedBonelossValues,
  ],
  (
    imageMeta,
    boneloss,
    bonelossPro,
    isEnabled,
    annotationsShown,
    showDynamicPbl,
    changedBonelossValues
  ) => {
    if (!showDynamicPbl) return
    if (!boneloss || !bonelossPro || !isEnabled || !annotationsShown) return

    return transformLandmark({
      meta: imageMeta,
      boneLoss: {
        annotations: [...changedBonelossValues], // Annotations that consider user changes
      },
    })
  }
)

export const getActiveToothPblLandmarks = createSelector(
  [getActiveTooth, getAnnotationsShown, getChangedBonelossValues],
  (activeTooth, annotationsShown, changedBonelossValues) => {
    if (!activeTooth || !annotationsShown) return

    const activeToothBoneloss: BonelossChange | undefined =
      changedBonelossValues.find((t) => t.toothName === activeTooth)
    if (!activeToothBoneloss || !activeToothBoneloss.landmarks) return

    const points = (landmarks: Landmark) => [
      ...(landmarks.d || [[0], [0], [0]]),
      ...(landmarks.m || [[0], [0], [0]]),
    ]

    return {
      landmarkPoints: points(activeToothBoneloss.landmarks),
      aiLandmarkPoints: points(
        activeToothBoneloss.aiLandmarks || activeToothBoneloss.landmarks
      ),
      adjusted: activeToothBoneloss.adjusted,
    }
  }
)

export const getImageLayers = createSelector(
  [
    entitiesSelector.getDetectionVisibility,
    getActiveNames,
    entitiesSelector.getDetections,
    getIsHighSenseModeActive,
    entitiesSelector.getAllUserChangesDuplicatedForEntities,
    getCariesPro,
    getBonelossPro,
    getLegacyBonelossLayer,
    getAllAdditions,
    getActiveAddition,
    getShowLetterBasedPeri,
    getShowNerveCanal,
    getFeatureNerveCanal,
  ],
  (
    visibleEntities,
    activeNames,
    entities,
    isHighSenseModeActive,
    changes,
    cariesPro,
    bonelossPro,
    bonelossLayer,
    additions,
    activeAddition,
    showLetterBasedPeri,
    showNerveCanal,
    featureNerveCanal
  ) => {
    const hsmData = parseHsmData(
      entities,
      changes,
      visibleEntities,
      cariesPro,
      isHighSenseModeActive,
      showLetterBasedPeri
    )

    const getOpacity = (name: string) => {
      // caries layers are painted at 75% opacity, other layers are painted with 100%
      return name === AnnotationName.caries ? CARIES_OPACITY : undefined
    }

    // we use DISABLING_CASES.caries assuming that it will be the same as DISABLING_CASES.apical
    const disabledHsmTeeth = visibleEntities
      .filter((v) =>
        DISABLING_CASES.caries.includes(
          v.detection.subtype as RestorationSubtype
        )
      )
      .map((tooth) => tooth.detection.toothName)

    // map tooth numbers that have a crown / bridge override

    const additionLayers = additions
      .filter((a) => a.mask)
      .map((a) => ({
        id: a.id!,
        imageUrl: `data:image/png;base64,${a.mask}`,
        name: a.type,
        visible:
          activeNames[a.type as keyof typeof activeNames] &&
          !bonelossPro &&
          !activeAddition?.ids?.includes(a.id!),
        opacity: getOpacity(a.subtype || a.type),
        toothName: a.toothName,
      }))

    // includes all teeth with new tooth positions
    const newTeethPositions = changes
      .filter((c) => c.newTooth)
      .map((change) => ({
        id: change.annotationId,
        toothName: change.newTooth,
      }))

    const replacedAdditionSubtypes = additions.flatMap((a) => a.rejectedIds)
    const rejectedDetections = changes.flatMap((c) => c.rejectedDetections)

    // Replace tooth number of moved annotation with new tooth number
    const visibleEntitiesWithUpdatedTeeth = visibleEntities.map((v) => ({
      ...v,
      detection: {
        ...v.detection,
        ...newTeethPositions.find((n) => n.id === v.detection.id),
      },
    }))

    const layers = visibleEntitiesWithUpdatedTeeth.map(
      ({ detection, name, visible }): ImageLayerData => ({
        id: detection.id,
        imageUrl: `data:image/png;base64,${detection.mask}`,
        name,
        visible:
          (name !== AnnotationName.nervus || !featureNerveCanal) &&
          (name !== AnnotationName.nerveCanal || showNerveCanal) &&
          !activeAddition?.ids?.includes(detection.id) &&
          activeNames[name as keyof typeof activeNames] &&
          !bonelossPro &&
          (visible ||
            !!crownOrBridgeOverride(bonelossPro, additions, detection)) &&
          !replacedAdditionSubtypes.includes(detection.id) &&
          !hsmData.hsmIdsToDisplay.includes(detection.id) &&
          !hsmData.hiddenIds.includes(detection.id) &&
          !rejectedDetections.includes(detection.id),
        opacity: getOpacity(name),
        addedSubtype: crownOrBridgeOverride(bonelossPro, additions, detection),
        bbox:
          activeNames[name as keyof typeof activeNames] &&
          !disabledHsmTeeth.includes(detection.toothName) &&
          hsmData.hsmIdsToDisplay.includes(detection.id)
            ? detection.bbox
            : undefined,
      })
    )

    return layers.concat(bonelossLayer, additionLayers)
  }
)

// A reusable approach for future coach marks with any diagnoses would be a selector that accepts argument
// https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument
/** Whether there is a calculus annotation to be rendered in the toothmap. */
export const getTeethMapHasCalculus = createSelector(
  [getTeethListForJawTeethMap, getIsBwOrToothBasedPeri],
  (teethList, isBwOrToothBasedPeri) =>
    isBwOrToothBasedPeri &&
    Object.values(teethList).some((list) =>
      list.some((e) => e.annotations?.calculus?.length)
    )
)

export const getShowTrialPeriodMarketingModal = createSelector(
  [getLicenceExpire, getLicense],
  (licenceExpire, license) => {
    const getLicenseExpirationDays = () => {
      if (!licenceExpire) return 0

      const today = new Date()
      const trialEnd = new Date(licenceExpire)
      return differenceInDays(trialEnd, today)
    }
    return license === License.Invalid || getLicenseExpirationDays() < 0
  }
)

export const getActiveToothHasBridge = createSelector(
  [getAnnotationsToRenderForActiveTooth],
  (annotations) =>
    annotations.some((a) => a.subtype === RestorationSubtype.bridges)
)

export const getCanGenerateReport = createSelector(
  [
    getBonelossPro,
    getBoneLossFormValues,
    getShowBoneLossLite,
    getDataIsChanged,
    getIsOwner,
  ],
  (bonelossPro, boneLossForm, showBonelossLite, canSave, isOwner) =>
    (!bonelossPro || !boneLossForm.draft || showBonelossLite) &&
    (!canSave || isOwner)
)

export const getSortedGroupedNoteEntries = createSelector(
  [getNotes],
  (notes) => {
    const byDate = groupBy(notes, (note) => transformDate(note.created))
    const groupedByDate = Array.from(byDate).reverse()
    const sortedEntriesByTime = (items: INote[]) => {
      return items.sort((a, b) => {
        const noteTime = (item: INote) => new Date(item.created)?.getTime()
        return noteTime(b) - noteTime(a)
      })
    }
    const sortedEntries: sortedINote[] = groupedByDate.map((groupedItems) => ({
      date: groupedItems[0],
      entries: sortedEntriesByTime(groupedItems[1]),
    }))
    return sortedEntries
  }
)

export const getShowPblLandmarks = (state: RootState) =>
  state.features.featurePblLandmarks &&
  (!isTouchDevice || state.imageControls.isEditingPblOnRadiograph)
