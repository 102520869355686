import { ActionType, getType } from "typesafe-actions"

import localStorage from "library/utilities/localStorage"
import { redirectToSsoLogout, setBearerToken } from "library/utilities/token"
import * as actions from "library/common/actions/user"
import {
  DEFAULT_THEME,
  WHATS_NEW_VERSION,
  DefaultCoachmarks,
} from "library/utilities/constants"
import {
  License,
  LogoutReason,
  NumberingSystem,
  Role,
} from "../types/userTypes"
import { ResultStatus } from "../types/dataStructureTypes"

type UserState = Readonly<{
  isAuthenticated: boolean
  username: string // this is the username for logging in ("testuser123")
  loginFailed: boolean
  failStatus: number
  newPasswordError: string | null
  oldPasswordError: string | null
  resetPasswordStatus: ResultStatus
  isMustResetPassword: boolean
  fullName: string // this is the first name of the user from the backend
  email: string // this is the contact email address of the user ("john@doe.com")
  serverError: string
  handlerName: string // for the integrated view: the current iPad user
  handlerHash: string // sha256 of the current handlerName
  SSO: boolean
  knownHandlers: string[] | null
  cariesPro: boolean
  bonelossPro: boolean | null
  setBoneLossPro: boolean | null
  boneLossLite: boolean
  uploadsRemaining: number | null
  whatsNew: string | null
  theme: actions.Theme
  lastCoachMarks: actions.CoachMarkRecord
  calculus: boolean
  nervus: boolean
  impacted: boolean
  license: License | null
  licenceExpire: string | null
  showDrawingMode: boolean
  boneLossOnly: boolean
  serverErrorMessage: string
  modalities: string[]
  numberingSystem: NumberingSystem
  toothBasedPeri: boolean
  role: Role
  generatedToken: actions.GeneratedToken | null
  logoutReason: LogoutReason
  companyId: string | undefined
  doctorId: string | undefined
  isIteroUser: boolean
  isIteroMidcUser: boolean
  impersonate: string | undefined
  installerByDefault: boolean
  hidePatientId: boolean
  showAlfaDocs: boolean
  alfaDocsApiKey: string
  userIntegrationResultStatus: ResultStatus
  userResultStatus: ResultStatus
  isOpenUserSettingsModal: boolean
}>

const token = sessionStorage.getItem("access_token")
export const initialUserState: UserState = {
  isAuthenticated: !!token,
  username: localStorage.getItem("username") || "",
  loginFailed: false,
  failStatus: 0,
  newPasswordError: null,
  oldPasswordError: null,
  resetPasswordStatus: ResultStatus.None,
  isMustResetPassword: false,
  SSO: false,
  fullName: "",
  email: "",
  handlerName: "",
  handlerHash: "",
  serverError: "",
  knownHandlers: null,
  cariesPro: false,
  bonelossPro: null,
  setBoneLossPro: null,
  boneLossLite: true,
  uploadsRemaining: null,
  whatsNew: null,
  theme: DEFAULT_THEME,
  lastCoachMarks: DefaultCoachmarks,
  calculus: false,
  nervus: false,
  impacted: false,
  license: null,
  licenceExpire: null,
  showDrawingMode: false,
  boneLossOnly: false,
  serverErrorMessage: "",
  modalities: [],
  numberingSystem: NumberingSystem.Fdi,
  toothBasedPeri: false,
  role: Role.None,
  generatedToken: null,
  logoutReason: LogoutReason.None,
  companyId: undefined,
  doctorId: undefined,
  isIteroUser: false,
  isIteroMidcUser: false,
  impersonate: undefined,
  installerByDefault: false,
  hidePatientId: false,
  showAlfaDocs: false,
  alfaDocsApiKey: "",
  userIntegrationResultStatus: ResultStatus.None,
  userResultStatus: ResultStatus.None,
  isOpenUserSettingsModal: false,
}
if (token) setBearerToken(token)

type UserActions = ActionType<typeof actions>

export default (state = initialUserState, action: UserActions): UserState => {
  switch (action.type) {
    case getType(actions.logOutAction):
      sessionStorage.removeItem("access_token")
      localStorage.removeItem("username")
      sessionStorage.removeItem("refresh_token")
      action.payload === LogoutReason.Button &&
        sessionStorage.removeItem("last_url")
      if (action.payload && action.payload !== LogoutReason.None && state.SSO) {
        redirectToSsoLogout()
      }
      sessionStorage.removeItem("id_token")
      return {
        ...initialUserState,
        isAuthenticated: false,
        loginFailed: false,
        username: state.username,
        logoutReason: action.payload,
      }

    case getType(actions.loginAction):
      return {
        ...state,
        isAuthenticated: true,
        logoutReason: LogoutReason.None,
      }

    case getType(actions.loginActionSuccess):
      return {
        ...state,
        username: action.payload.username,
        loginFailed: false,
      }

    case getType(actions.loginActionError):
      return {
        ...state,
        loginFailed: true,
        failStatus: action.payload,
      }
    case getType(actions.resetErrorAction):
      return {
        ...state,
        loginFailed: false,
        failStatus: 0,
      }

    case getType(actions.newPasswordErrorAction):
      return {
        ...state,
        newPasswordError: action.payload,
      }

    case getType(actions.oldPasswordErrorAction):
      return {
        ...state,
        oldPasswordError: action.payload,
      }

    case getType(actions.setResetPasswordStatus): {
      return {
        ...state,
        resetPasswordStatus: action.payload,
      }
    }
    case getType(actions.setMustResetPassword):
      return {
        ...state,
        isMustResetPassword: action.payload,
      }

    case getType(actions.setUserInfo):
      const {
        fullName,
        email,
        SSO,
        knownHandlers,
        cariesPro,
        bonelossPro,
        boneLossLite,
        uploadsRemaining,
        whatsNew,
        theme,
        lastCoachMarks,
        calculus,
        nervus,
        impacted,
        license,
        licenceExpire,
        showDrawingMode,
        boneLossOnly,
        mustResetPassword,
        modalities,
        numberingSystem,
        toothBasedPeri,
        role,
        companyId,
        doctorId,
        isIteroUser,
        isIteroMidcUser,
        installerByDefault,
        hidePatientId,
        showAlfaDocs,
        alfaDocsApiKey,
        username,
      } = action.payload

      return {
        ...state,
        fullName: fullName || state.fullName,
        email: email || state.email,
        SSO: SSO ?? state.SSO,
        knownHandlers: knownHandlers ?? state.knownHandlers,
        cariesPro: cariesPro ?? state.cariesPro,
        bonelossPro:
          state.setBoneLossPro !== null
            ? state.setBoneLossPro
            : bonelossPro === undefined
              ? state.bonelossPro
              : bonelossPro,
        boneLossLite: boneLossLite ?? state.boneLossLite,
        uploadsRemaining: uploadsRemaining ?? state.uploadsRemaining,
        whatsNew: whatsNew ?? state.whatsNew,
        theme: theme ?? state.theme,
        lastCoachMarks: lastCoachMarks ?? state.lastCoachMarks,
        calculus: calculus ?? state.calculus,
        nervus: nervus ?? state.nervus,
        impacted: impacted ?? state.impacted,
        license: license ?? state.license,
        licenceExpire: licenceExpire ?? state.licenceExpire,
        showDrawingMode: showDrawingMode ?? state.showDrawingMode,
        boneLossOnly: boneLossOnly ?? state.boneLossOnly,
        isMustResetPassword: mustResetPassword ?? state.isMustResetPassword,
        modalities: modalities ?? state.modalities,
        numberingSystem: numberingSystem ?? state.numberingSystem,
        toothBasedPeri: toothBasedPeri ?? state.toothBasedPeri,
        role: role ?? state.role,
        companyId: companyId ?? state.companyId,
        doctorId: doctorId ?? state.doctorId,
        isIteroUser: isIteroUser ?? state.isIteroUser,
        isIteroMidcUser: isIteroMidcUser ?? state.isIteroMidcUser,
        installerByDefault: installerByDefault ?? state.installerByDefault,
        hidePatientId: hidePatientId ?? state.hidePatientId,
        showAlfaDocs: showAlfaDocs ?? state.showAlfaDocs,
        alfaDocsApiKey: alfaDocsApiKey ?? state.alfaDocsApiKey,
        username: username ?? state.username,
      }

    case getType(actions.setServerError):
      return {
        ...state,
        serverError: action.payload,
      }

    case getType(actions.setServerErrorMessage):
      return {
        ...state,
        serverErrorMessage: action.payload,
      }

    case getType(actions.setHandlerName):
      return {
        ...state,
        handlerName: action.payload,
      }

    case getType(actions.setHandlerHash):
      return {
        ...state,
        handlerHash: action.payload,
      }

    case getType(actions.toggleCariesPro):
      return {
        ...state,
        cariesPro: !state.cariesPro,
      }

    case getType(actions.toggleBonelossPro):
      return {
        ...state,
        bonelossPro: !state.bonelossPro,
      }

    case getType(actions.toggleDrawingMode):
      return {
        ...state,
        showDrawingMode: !state.showDrawingMode,
      }

    case getType(actions.setBonelossPro):
      return {
        ...state,
        setBoneLossPro: action.payload,
      }

    case getType(actions.setWhatsNew):
      return {
        ...state,
        whatsNew: WHATS_NEW_VERSION,
      }
    case getType(actions.setGeneratedToken):
      return {
        ...state,
        generatedToken: action.payload,
      }

    case getType(actions.setLastCoachMarks):
      const { coachMarkFeature, coachMarkIndex } = action.payload
      return {
        ...state,
        lastCoachMarks: {
          ...state.lastCoachMarks,
          [coachMarkFeature]: coachMarkIndex,
        },
      }

    case getType(actions.setImpersonate):
      return {
        ...state,
        impersonate: action.payload,
      }

    case getType(actions.setUserIntegrationResultStatus):
      return {
        ...state,
        userIntegrationResultStatus: action.payload,
      }

    case getType(actions.setUserResultStatus):
      return {
        ...state,
        userResultStatus: action.payload,
      }
    case getType(actions.setIsOpenUserSettingsModal):
      return {
        ...state,
        isOpenUserSettingsModal: action.payload,
      }

    default:
      return state
  }
}
