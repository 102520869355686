import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/upload"

import {
  ICurrentBatchUpload,
  IUploadFiles,
  ToothWithDetection,
} from "../types/uploadTypes"

type UploadState = Readonly<{
  idImage: string
  showReport: boolean
  showButton: boolean
  showImmediately: boolean
  demoMode: boolean
  imageBase64: string
  pending: boolean
  dateOfBirth: string
  originalDateOfBirth: string
  imageDateCreate: string
  imageDate: string
  originalImageDate: string
  patientName: string
  originalPatientName: string
  preloader: boolean
  dataImage: any
  statusFilter: boolean
  newDataTooth: ToothWithDetection[]
  globalComment: string
  reportSent: boolean
  xRayType: string
  patientID: string
  originalPatientID: string
  orginalChanges: ToothWithDetection[]
  isFlipped: boolean
  originalIsFlippedStatus: boolean
  rotate: number
  imageFormatOrig: string
  isSavingChanges: boolean
  originalGlobalComment: string
  fileName: string
  imageSizeError: string
  reportProblemId: string
  shouldTakeScreenshot: boolean
  screenshot: string
  isPermissionError: boolean
  data: any
  openDateMs: number
  highSensitivity: string | undefined
  highSensitivityAvailable: Set<string>
  currentBatchUpload: ICurrentBatchUpload
  uploadFiles: IUploadFiles
}>

const initialState: UploadState = {
  idImage: "",
  pending: false,
  showReport: false,
  showButton: true,
  showImmediately: false,
  demoMode: false,
  preloader: false,
  imageBase64: "",
  dateOfBirth: "",
  originalDateOfBirth: "",
  imageDateCreate: "",
  imageDate: "",
  originalImageDate: "",
  patientName: "",
  originalPatientName: "",
  globalComment: "",
  dataImage: {},
  newDataTooth: [],
  statusFilter: true,
  reportSent: true,
  xRayType: "",
  patientID: "",
  originalPatientID: "",
  orginalChanges: [],
  isFlipped: false,
  originalIsFlippedStatus: false,
  rotate: 0,
  imageFormatOrig: "",
  isSavingChanges: false,
  originalGlobalComment: "",
  fileName: "",
  imageSizeError: "",
  reportProblemId: "",
  shouldTakeScreenshot: false,
  screenshot: "",
  isPermissionError: false,
  data: null,
  openDateMs: 0,
  highSensitivity: undefined,
  highSensitivityAvailable: new Set<string>(),
  currentBatchUpload: {
    total: null,
    error: null,
    success: null,
  },
  uploadFiles: {
    acceptedFiles: [],
    rejectedFiles: [],
    conflictedFiles: [],
  },
}

type UploadActions = ActionType<typeof actions>

export default (state = initialState, action: UploadActions): UploadState => {
  switch (action.type) {
    case getType(actions.setShouldTakeScreenshot):
      return {
        ...state,
        shouldTakeScreenshot: action.payload,
      }

    case getType(actions.setScreenshot):
      return {
        ...state,
        screenshot: action.payload,
      }
    case getType(actions.setReportProblemId):
      return { ...state, reportProblemId: action.payload }
    case getType(actions.uploadActionPreloaderImage):
      return {
        ...state,
        preloader: true,
      }
    case getType(actions.uploadActionSetImageSizeError):
      return {
        ...state,
        imageSizeError: action.payload,
      }
    case getType(actions.uploadActionSuccess):
      return {
        ...state,
        pending: true,
        preloader: false,
      }
    case getType(actions.uploadActionError):
      return {
        ...state,
        preloader: false,
      }
    case getType(actions.uploadActionNewImage):
      return {
        ...initialState,
        demoMode: state.demoMode,
      }
    case getType(actions.setDemoMode):
      return {
        ...state,
        demoMode: true,
      }
    case getType(actions.setCurrentBatchUpload):
      const { id, status } = action.payload
      return {
        ...state,
        currentBatchUpload: {
          ...state.currentBatchUpload,
          [status]: [...(state.currentBatchUpload[status] || []), id],
        },
      }
    case getType(actions.clearUploadFiles):
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          acceptedFiles: [],
          rejectedFiles: [],
        },
      }
    case getType(actions.removeAcceptedFile):
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          acceptedFiles: state.uploadFiles.acceptedFiles.filter(
            (_, index) => index !== action.payload
          ),
        },
      }
    case getType(actions.setAcceptedFiles):
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          acceptedFiles: [...state.uploadFiles.acceptedFiles, action.payload],
        },
      }
    case getType(actions.setRejectedFiles):
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          rejectedFiles: [...state.uploadFiles.rejectedFiles, action.payload],
        },
      }
    case getType(actions.setConflictedFiles):
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          conflictedFiles: [
            ...state.uploadFiles.conflictedFiles,
            action.payload,
          ],
        },
      }
    case getType(actions.clearConflictedFile):
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          conflictedFiles: state.uploadFiles.conflictedFiles.filter(
            (file) => file.id !== action.payload
          ),
        },
      }
    default:
      return state
  }
}
