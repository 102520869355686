import { flipTooth } from "@dentalxrai/transform-landmark-to-svg"
import { UserChange } from "library/common/types/dataStructureTypes"
import { NumberingSystem } from "library/common/types/userTypes"
import { ICroppedTeeth } from "library/common/types/dataStructureTypes"
import { CroppedTeethDirection } from "library/common/types/adjustmentTypes"
import {
  AnnotationOnTooth,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"
import { Detection } from "library/common/types/dataStructureTypes"

export const visualQuadrant = (tooth: number, flip: boolean) =>
  Math.floor(flipTooth(tooth, flip) / 10)

export function flipChanges(changes: UserChange[], flip = true): UserChange[] {
  return flip
    ? changes.map((change) =>
        change.newTooth
          ? {
              ...change,
              newTooth: flipTooth(change.newTooth),
            }
          : change
      )
    : changes
}

export function flipMovedTeeth(
  movedTeeth: Record<string, number>
): Record<string, number> {
  const newMovedTeeth: Record<string, number> = {}
  Object.entries(movedTeeth).forEach(([k, v]) => {
    newMovedTeeth[`${flipTooth(+k)}`] = flipTooth(v)
  })
  return newMovedTeeth
}

export const displayToothName = (tooth: number, system: NumberingSystem) => {
  if (system !== NumberingSystem.Universal) return tooth

  switch (Math.floor(tooth / 10)) {
    case 1:
      return 19 - tooth
    case 2:
      return tooth - 12
    case 3:
      return 55 - tooth
    case 4:
      return tooth - 16
    default:
      return 0
  }
}

export const offsetFromLeft = (tooth: number) => {
  if (tooth < 20) {
    return 18 - tooth
  } else if (tooth < 30) {
    return tooth - 21 + 8
  } else if (tooth < 40) {
    return tooth - 31 + 8
  } else {
    return 48 - tooth
  }
}

export const isUpper = (tooth: number) => tooth < 30

const flipCroppedTeethDirection = (value: CroppedTeethDirection) => {
  //CroppedToothDirection with "lr" is not being supported yet.
  switch (value) {
    case CroppedTeethDirection.Right:
      return CroppedTeethDirection.Left
    case CroppedTeethDirection.Left:
      return CroppedTeethDirection.Right
    default:
      return CroppedTeethDirection.None
  }
}

export const flipCroppedTeeth = (
  croppedTeeth: ICroppedTeeth | undefined,
  flip: boolean
) => {
  if (!croppedTeeth || !flip) return croppedTeeth
  return Object.fromEntries(
    Object.entries(croppedTeeth).map(([tooth, dir]) => [
      flipTooth(+tooth),
      flipCroppedTeethDirection(dir),
    ])
  )
}

export const subtypeIsCrownOrBridge = (subtype: string | undefined) =>
  subtype === RestorationSubtype.bridges ||
  subtype === RestorationSubtype.crowns

export const crownOrBridgeOverride = (
  bonelossPro: boolean | null,
  additions: AnnotationOnTooth[],
  detection: Detection
) => {
  const crownBridgeOverride = new Map<number, RestorationSubtype>()
  if (!bonelossPro) {
    additions.forEach((a) => {
      if (subtypeIsCrownOrBridge(a.subtype)) {
        crownBridgeOverride.set(a.toothName, a.subtype!)
      }
    })
  }

  // calculate the overwritten subtype for recoloring the annotation
  return subtypeIsCrownOrBridge(detection.subtype) &&
    detection.subtype !== crownBridgeOverride.get(detection.toothName)
    ? crownBridgeOverride.get(detection.toothName)
    : undefined
}

export const bonelossPercentage = (maxBoneLossPercent: number) => {
  if (!maxBoneLossPercent) return
  switch (true) {
    case maxBoneLossPercent < 15:
      return "<15"
    case maxBoneLossPercent <= 33:
      return "15-33"
    default:
      return ">33"
  }
}
