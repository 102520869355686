import { createAction } from "typesafe-actions"

import {
  License,
  LogoutReason,
  NumberingSystem,
  Role,
  UserTypes,
} from "../types/userTypes"
import { RegulatoryMarket } from "../types/serverDataTypes"
import { ResultStatus } from "../types/dataStructureTypes"
import { AuthenticationResult } from "@azure/msal-browser"

export interface ActionLogin {
  isSSO: boolean
  username: string
  password?: string
  redirect_uri?: string
  directLoginUsername?: string
  isRefreshToken?: boolean
}

export interface ActionResetPasswordFirstTime {
  newPassword: string
  fullName: string
  email: string
  telephoneNumber: string
  token: string
}

// Keep key-value pairs lowercase as it is used with state objects
export enum Theme {
  dentalxrai = "dentalxrai",
  carestream = "carestream",
}

export enum CoachMarkFeature {
  CariesPro = "cariesPro",
  AutoCariesPro = "autoCariesPro",
  BonelossPro = "bonelossPro",
  CalculusXrayImage = "calculusXrayImage",
  CalculusToothMap = "calculusToothMap",
  CalculusEditView = "calculusEditView",
  CariesOnOpg = "cariesOnOpg",
}

export enum CoachMarkIndex {
  First = 1,
}

export type CoachMarkRecord = Record<CoachMarkFeature, CoachMarkIndex>

export type GeneratedToken = {
  token: string
  sessionId?: string
}

export interface ActionUserInfo {
  fullName?: string
  email?: string
  SSO?: boolean
  knownHandlers?: string[]
  cariesPro?: boolean
  bonelossPro?: boolean
  boneLossLite?: boolean
  uploadsRemaining?: number
  whatsNew?: string
  lastCoachMarks?: CoachMarkRecord
  theme?: Theme
  calculus?: boolean
  nervus?: boolean
  impacted?: boolean
  license?: License
  licenceExpire?: string
  showDrawingMode?: boolean
  boneLossOnly?: boolean
  mustResetPassword?: boolean
  modalities?: string[]
  numberingSystem?: NumberingSystem
  toothBasedPeri?: boolean
  role?: Role
  companyId?: string
  doctorId?: string
  isIteroUser?: boolean
  isIteroMidcUser?: boolean
  installerByDefault?: boolean
  hidePatientId?: boolean
  showAlfaDocs?: boolean
  alfaDocsApiKey?: string
  regulatoryMarket?: RegulatoryMarket
  username?: string
}

export interface ActionLastCoachMark {
  coachMarkFeature: CoachMarkFeature
  coachMarkIndex: CoachMarkIndex
}

export const logOutAction = createAction(
  UserTypes.LOGOUT_ACTION
)<LogoutReason>()
export const resetErrorAction = createAction(UserTypes.RESET_ERROR_ACTION)()
export const loginAction = createAction(UserTypes.LOGIN_ACTION)()
export const loginActionSuccess = createAction(
  UserTypes.LOGIN_ACTION_SUCCESS
)<ActionLogin>()
export const loginActionError = createAction(
  UserTypes.LOGIN_ACTION_ERROR
)<number>()
export const loginActionWithData = createAction(
  UserTypes.LOGIN_ACTION_WITH_DATA
)<ActionLogin>()
export const resetPasswordAction = createAction(
  UserTypes.RESET_PASSWORD_ACTION
)<{ oldPassword: string; newPassword: string }>()
export const newPasswordErrorAction = createAction(
  UserTypes.NEW_PASSWORD_ERROR_ACTION
)<string>()
export const oldPasswordErrorAction = createAction(
  UserTypes.OLD_PASSWORD_ERROR_ACTION
)<string>()
export const setResetPasswordStatus = createAction(
  UserTypes.SET_RESET_PASSWORD_STATUS
)<ResultStatus>()
export const setMustResetPassword = createAction(
  UserTypes.SET_MUST_RESET_PASSWORD_ACTION
)<boolean>()
export const resetPasswordFirstTimeAction = createAction(
  UserTypes.RESET_PASSWORD_FIRST_TIME_ACTION
)<ActionResetPasswordFirstTime>()
export const getUserName = createAction(UserTypes.GET_USER_NAME)()
export const setUserResultStatus = createAction(
  UserTypes.SET_USER_RESULT_STATUS
)<ResultStatus>()
export const setUserInfo = createAction(
  UserTypes.SET_USER_INFO
)<ActionUserInfo>()
export const setServerError = createAction(UserTypes.SET_SERVER_ERROR)<string>()
export const setServerErrorMessage = createAction(
  UserTypes.SET_SERVER_ERROR_MESSAGE
)<string>()
export const setHandlerName = createAction(UserTypes.SET_HANDLER_NAME)<string>()
export const setHandlerHash = createAction(UserTypes.SET_HANDLER_HASH)<string>()
export const submitHandlerHash = createAction(UserTypes.SUBMIT_HANDLER_HASH)()
export const toggleCariesPro = createAction(UserTypes.TOGGLE_CARIES_PRO)()
export const toggleBonelossPro = createAction(UserTypes.TOGGLE_BONELOSS_PRO)()
export const toggleDrawingMode = createAction(UserTypes.TOGGLE_DRAWING_MODE)()
export const setBonelossPro = createAction(
  UserTypes.SET_BONELOSS_PRO
)<boolean>()
export const setWhatsNew = createAction(UserTypes.SET_WHATS_NEW)()
export const generateToken = createAction(UserTypes.GENERATE_TOKEN)()
export const setGeneratedToken = createAction(
  UserTypes.SET_GENERATED_TOKEN
)<GeneratedToken>()

export const setLastCoachMarks = createAction(
  UserTypes.SET_LAST_COACH_MARKS
)<ActionLastCoachMark>()
export const setImpersonate = createAction(UserTypes.SET_IMPERSONATE)<string>()
export const setAlfaDocsApiKey = createAction(
  UserTypes.SET_ALFA_DOCS_API_KEY
)<string>()
export const setUserIntegrationResultStatus = createAction(
  UserTypes.SET_USER_INTEGRATION_RESULT_STATUS
)<ResultStatus>()
export const setIsOpenUserSettingsModal = createAction(
  UserTypes.SET_IS_OPEN_USER_SETTINGS_MODAL
)<boolean>()
export const loginAzureadSuccess = createAction(
  UserTypes.LOGIN_AZUREAD_SUCCESS
)<AuthenticationResult>()
