import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { Detection } from "./dataStructureTypes"
import { ActionWithData } from "../actions/upload"

export enum UploadTypes {
  UPLOAD_ACTION_NEW_IMAGE = "@@UPLOAD/UPLOAD_ACTION_NEW_IMAGE",
  UPLOAD_ACTION_PRELOADER_IMAGE = "@@UPLOAD/UPLOAD_ACTION_PRELOADER_IMAGE",
  UPLOAD_ACTION_ERROR = "@@UPLOAD/UPLOAD_ACTION_ERROR",
  UPLOAD_ACTION_DATA_IMAGE = "@@UPLOAD/UPLOAD_ACTION_DATA_IMAGE",
  UPLOAD_ACTION_DATA_IMAGE_SUCCESS = "@@UPLOAD/UPLOAD_ACTION_DATA_IMAGE_SUCCESS",
  UPLOAD_ACTION_WITH_DATA = "@@UPLOAD/UPLOAD_ACTION_WITH_DATA",
  UPLOAD_ACTION_SUCCESS = "@@UPLOAD/UPLOAD_ACTION_SUCCESS",
  UPLOAD_ACTION_SAVE_USER_META_DATA = "@@UPLOAD/UPLOAD_ACTION_SAVE_USER_META_DATA",
  UPLOAD_ACTION_SEND_REPORT_PROBLEM = "@@UPLOAD/UPLOAD_ACTION_SEND_REPORT_PROBLEM",
  UPLOAD_ACTION_SET_REPORT_PROBLEM_ID = "@@UPLOAD/UPLOAD_ACTION_SET_REPORT_PROBLEM_ID",
  UPLOAD_ACTION_SET_SHOULD_TAKE_SCREENSHOT = "@@UPLOAD/UPLOAD_ACTION_SET_SHOULD_TAKE_SCREENSHOT",
  UPLOAD_ACTION_SET_SCREENSHOT = "@@UPLOAD/UPLOAD_ACTION_SET_SCREENSHOT",
  SET_USER_INFO = "@@UPLOAD/SET_USER_INFO",
  UPLOAD_ACTION_SET_IMAGE_SIZE_ERROR = "@@UPLOAD/UPLOAD_ACTION_SET_IMAGE_SIZE_ERROR",
  UPLOAD_ACTION_SET_DEMO_MODE = "@@UPLOAD/UPLOAD_ACTION_SET_DEMO_MODE",
  SET_CURRENT_BATCH_UPLOAD = "@@UPLOAD/SET_CURRENT_BATCH_UPLOAD",
  CLEAR_UPLOAD_FILES = "@@UPLOAD/CLEAR_UPLOAD_FILES",
  REMOVE_ACCEPTED_FILE = "@@UPLOAD/REMOVE_ACCEPTED_FILE",
  SET_ACCEPTED_FILES = "@@UPLOAD/SET_ACCEPTED_FILES",
  SET_REJECTED_FILES = "@@UPLOAD/SET_REJECTED_FILES",
  SET_CONFLICTED_FILES = "@@UPLOAD/SET_CONFLICTED_FILES",
  CLEAR_CONFLICTED_FILE = "@@UPLOAD/CLEAR_CONFLICTED_FILE",
}

export interface ICurrentBatchUpload {
  total: string[] | null
  error: string[] | null
  success: string[] | null
}

export interface ToothWithDetection {
  toothName: string
  apical: boolean
  bone: boolean
  bridges: boolean
  caries: boolean
  crowns: boolean
  roots: boolean
  fillings: boolean
  implants: boolean
  toothDelete: boolean
  comment: string
}

export type DataImage = Readonly<{
  apical: Detection[]
  caries: Detection[]
  restorations: Detection[]
  paro: Detection[]
  teeth: Tooth[]
}>

export enum ErrorType {
  FileType = "FileType",
  Size = "Size",
}
export interface IAcceptedFiles {
  file: ActionWithData
  preview: string
}
export interface IRejectedFiles {
  fileName: string
  errorType: ErrorType
  preview?: string
}
export interface IUploadFiles {
  acceptedFiles: IAcceptedFiles[]
  rejectedFiles: IRejectedFiles[]
  conflictedFiles: IConflictedFile[]
}
export interface PatientMeta {
  patientID: string
  patientName: string
  dateOfBirth: string
}
export interface IConflictedFile {
  currentPatientInfo: PatientMeta
  imagePatientInfo: PatientMeta
  dateOfRadiograph: string
  fileName: string
  id: string
}
