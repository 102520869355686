import {
  AUTH0_BASE_URI,
  OAUTH_BASE_URI,
  RENEW_SUBSCRIPTION_URL,
  RENEW_SUBSCRIPTION_URL_AUTH0,
} from "./constants"
import fetch from "./fetch"
import { v4 as uuidv4 } from "uuid"

export const setBearerToken = (token: string | null) => {
  fetch.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const hasBearerToken = () =>
  !!fetch.defaults.headers.common.Authorization

export const saveOauthResponse = (response?: {
  refresh_token?: string
  id_token?: string
}) => {
  if (!response) return
  const refreshToken = response.refresh_token
  refreshToken && sessionStorage.setItem("refresh_token", refreshToken)
  const idToken = response.id_token
  idToken && sessionStorage.setItem("id_token", idToken)
}

export const clearSsoProvider = () => {
  sessionStorage.removeItem("sso_provider")
  sessionStorage.removeItem("sso_provider_type")
}

export const setSsoProvider = (provider: string) =>
  sessionStorage.setItem("sso_provider", provider)
export const getSsoProvider = () => sessionStorage.getItem("sso_provider")

export const setSsoProviderType = (provider: SsoProviderType) =>
  sessionStorage.setItem("sso_provider_type", provider)
export const getSsoProviderType = () =>
  sessionStorage.getItem("sso_provider_type")

export const isAuth0 = () => getSsoProviderType() === SsoProviderType.Auth0

export const getProfileUrl = () =>
  isAuth0() && RENEW_SUBSCRIPTION_URL_AUTH0
    ? `${RENEW_SUBSCRIPTION_URL_AUTH0}/profile/profile`
    : null

export const getRenewUrl = () =>
  isAuth0() ? RENEW_SUBSCRIPTION_URL_AUTH0 : RENEW_SUBSCRIPTION_URL

export const getRedirectUrl = (isAuth0: boolean) =>
  window.location.origin + (isAuth0 ? "/login/auth0" : "/login/wso2")

export enum SsoProviderType {
  Wso2 = "wso2",
  Itero = "itero",
  Auth0 = "auth0",
}

export const findProviderType = (isAuth0: boolean) =>
  isAuth0
    ? SsoProviderType.Auth0
    : getSsoProviderType() === SsoProviderType.Itero
      ? SsoProviderType.Itero
      : SsoProviderType.Wso2

export const redirectToSsoLogin = (providerType: SsoProviderType) => {
  setSsoProviderType(providerType)
  const isAuth0 = providerType === SsoProviderType.Auth0
  const redirect_uri = getRedirectUrl(isAuth0)
  const state = uuidv4()
  sessionStorage.setItem("oauth_state", state)
  const scope = isAuth0 ? "offline_access profile email openid" : "openid"
  const params = new URLSearchParams({ redirect_uri, state, scope })
  providerType === SsoProviderType.Itero &&
    params.set("loginPage", "itero_app_login.jsp")
  const baseUri = isAuth0 ? AUTH0_BASE_URI : OAUTH_BASE_URI
  window.location.href = `${baseUri}&response_type=code&${params}`
}

export const redirectToSsoLogout = () => {
  const providerType = getSsoProviderType()
  if (!providerType) return

  const isAuth0 = providerType === SsoProviderType.Auth0
  const baseUri = isAuth0 ? AUTH0_BASE_URI : OAUTH_BASE_URI
  if (!baseUri) return

  const id_token_hint = sessionStorage.getItem("id_token")
  sessionStorage.removeItem("id_token")

  const params = new URLSearchParams({
    post_logout_redirect_uri:
      window.location.origin + (isAuth0 ? "/login?loggedOut=1" : "/login/wso2"),
  })
  id_token_hint && params.set("id_token_hint", id_token_hint)
  window.location.href = `${new URL(baseUri).origin}/oidc/logout?${params}`
}
