import { ActionType, getType } from "typesafe-actions"
import * as actions from "library/common/actions/filters"

// FilterStatus enum values to be camelCase as it is used with the state object
export enum FilterStatus {
  IsEnabled = "isEnabled",
  ShowCaries = "showCaries",
  ShowApical = "showApical",
  ShowOther = "showOther",
  ShowNerveCanal = "showNerveCanal",
}

export type FiltersState = Readonly<{
  isEnabled: boolean
  showCaries: boolean
  showApical: boolean
  showOther: boolean
  showNerveCanal: boolean
  isHighSenseModeActive: boolean
}>

export const initialFilterState: FiltersState = {
  isEnabled: true,
  showCaries: true,
  showApical: true,
  showOther: true,
  showNerveCanal: false,
  isHighSenseModeActive: false,
}
type FiltersActions = ActionType<typeof actions>

export default (
  state = initialFilterState,
  action: FiltersActions
): FiltersState => {
  switch (action.type) {
    case getType(actions.setFiltersStatus):
      return { ...action.payload }

    case getType(actions.toggleHSM):
      return { ...state, isHighSenseModeActive: !state.isHighSenseModeActive }

    case getType(actions.setHSM):
      return { ...state, isHighSenseModeActive: action.payload }

    case getType(actions.setInitialState):
      return {
        ...initialFilterState,
        isHighSenseModeActive: state.isHighSenseModeActive,
      }

    case getType(actions.setIsEnabled):
      return {
        ...initialFilterState,
        isEnabled: action.payload,
      }

    default:
      return state
  }
}
