import { createAction } from "typesafe-actions"

import {
  DataImage,
  IAcceptedFiles,
  IConflictedFile,
  IRejectedFiles,
  ToothWithDetection,
  UploadTypes,
} from "../types/uploadTypes"

export interface IMetaData {
  dateOfBirth: string | null
  imageDate: string | null
  patientName: string
  patientID: string
}

interface ActionGetDataImageSuccess {
  dataImage: DataImage
  newDataTooth: ToothWithDetection[]
  meta: IMetaData & {
    kind: string
    imageDateCreate: string
  }
}

export interface ActionWithData {
  meta: {
    patientID?: string
    patientName?: string
    dateOfBirth?: string
    patientUuid?: string
    externalImageUID?: string
    imageDateCreate: string
    imageDate: string
    fileName: string
  }
  data: File
}

export interface IReportProblem {
  info: {
    description: string
    userName: string
    email: string
  }
  screenshot: string
  url: string
}
export interface IUpload {
  id: string
  status: "success" | "error" | "total"
}

export const uploadActionNewImage = createAction(
  UploadTypes.UPLOAD_ACTION_NEW_IMAGE
)()
export const uploadActionPreloaderImage = createAction(
  UploadTypes.UPLOAD_ACTION_PRELOADER_IMAGE
)()
export const uploadActionError = createAction(UploadTypes.UPLOAD_ACTION_ERROR)()
export const uploadActionSuccess = createAction(
  UploadTypes.UPLOAD_ACTION_SUCCESS
)()
export const uploadActionGetDataImageSuccess = createAction(
  UploadTypes.UPLOAD_ACTION_DATA_IMAGE_SUCCESS
)<ActionGetDataImageSuccess>()
export const uploadActionWithData = createAction(
  UploadTypes.UPLOAD_ACTION_WITH_DATA
)<ActionWithData | ActionWithData[]>()
export const uploadActionSaveUserMetaData = createAction(
  UploadTypes.UPLOAD_ACTION_SAVE_USER_META_DATA
)<IMetaData>()
export const sendReportProblem = createAction(
  UploadTypes.UPLOAD_ACTION_SEND_REPORT_PROBLEM
)<IReportProblem>()
export const setReportProblemId = createAction(
  UploadTypes.UPLOAD_ACTION_SET_REPORT_PROBLEM_ID
)<string>()
export const setShouldTakeScreenshot = createAction(
  UploadTypes.UPLOAD_ACTION_SET_SHOULD_TAKE_SCREENSHOT
)<boolean>()
export const setScreenshot = createAction(
  UploadTypes.UPLOAD_ACTION_SET_SCREENSHOT
)<string>()
export const uploadActionSetImageSizeError = createAction(
  UploadTypes.UPLOAD_ACTION_SET_IMAGE_SIZE_ERROR
)<string>()
export const setDemoMode = createAction(
  UploadTypes.UPLOAD_ACTION_SET_DEMO_MODE
)()
export const setCurrentBatchUpload = createAction(
  UploadTypes.SET_CURRENT_BATCH_UPLOAD
)<IUpload>()
export const clearUploadFiles = createAction(UploadTypes.CLEAR_UPLOAD_FILES)()
export const removeAcceptedFile = createAction(
  UploadTypes.REMOVE_ACCEPTED_FILE
)<number>()
export const setAcceptedFiles = createAction(
  UploadTypes.SET_ACCEPTED_FILES
)<IAcceptedFiles>()
export const setRejectedFiles = createAction(
  UploadTypes.SET_REJECTED_FILES
)<IRejectedFiles>()
export const setConflictedFiles = createAction(
  UploadTypes.SET_CONFLICTED_FILES
)<IConflictedFile>()
export const clearConflictedFile = createAction(
  UploadTypes.CLEAR_CONFLICTED_FILE
)<string>()
