import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { history, store } from "core/store/configureStore"
import configureI18n from "core/i18n/configureI18n"

import Routes from "core/Routes"

import "resources/styles/index.scss"
import { ThemeProvider } from "components/atoms/Baseline/Baseline.context"
import { FullScreenNotification } from "components/atoms/FullScreenNotification"
import { Baseline } from "components/atoms/Baseline"
import UrlParamContext from "library/utilities/UrlParamContext"

import { MsalProvider } from "@azure/msal-react"
import { Configuration, PublicClientApplication } from "@azure/msal-browser"

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZUREAD_CLIENT_ID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZUREAD_TENNANT_ID}`,
    knownAuthorities: [],
    cloudDiscoveryMetadata: "",
    redirectUri: window.location.href,
    postLogoutRedirectUri: "/login",
    navigateToLoginRequestUrl: true,
    clientCapabilities: ["CP1"],
    protocolMode: "AAD",
  },
}

const pca = new PublicClientApplication(configuration)

async function render() {
  await configureI18n.promise

  createRoot(document.getElementById("root")!).render(
    <ThemeProvider>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <UrlParamContext>
              <Baseline>
                <Routes />
                <FullScreenNotification />
              </Baseline>
            </UrlParamContext>
          </ConnectedRouter>
        </Provider>
      </MsalProvider>
    </ThemeProvider>
  )
}

void render()
