import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/imageControls"

export enum ActiveFilter {
  Navigation = "Navigation",
  Contrast = "Contrast",
  Opacity = "Opacity",
}

type ImageControlsState = Readonly<{
  width: number | null
  height: number | null
  activeFilter: ActiveFilter
  isFullscreen: boolean
  usedFullscreen: boolean
  isLargerAspectRatioScreen: boolean
  isSmallScreen: boolean
  showDynamicPbl: boolean
  isMeasurementToolActive: boolean
  shouldResetZoom: boolean
  scale: number
  isEditingPblOnRadiograph: boolean
}>

export const initialImageControlsState: ImageControlsState = {
  scale: 1,
  width: null,
  height: null,
  activeFilter: ActiveFilter.Navigation,
  isFullscreen: false,
  usedFullscreen: false,
  isLargerAspectRatioScreen: true,
  isSmallScreen: false,
  showDynamicPbl: false,
  isMeasurementToolActive: false,
  shouldResetZoom: false,
  isEditingPblOnRadiograph: false,
}

type ImageControlsActions = ActionType<typeof actions>

export default (
  state = initialImageControlsState,
  action: ImageControlsActions
): ImageControlsState => {
  switch (action.type) {
    case getType(actions.setZoom):
      return { ...state, scale: action.payload }

    case getType(actions.setDimensions):
      const { width, height } = action.payload

      return { ...state, width, height }

    case getType(actions.setActiveFilterSuccess):
      return { ...state, activeFilter: action.payload }

    case getType(actions.setInitialState):
      return {
        ...initialImageControlsState,
        width: state.width,
        height: state.height,
      }

    case getType(actions.toggleFullscreen):
      return {
        ...state,
        isFullscreen: !state.isFullscreen,
        usedFullscreen: state.isFullscreen || state.usedFullscreen,
      }
    case getType(actions.toggleIsMeasurementToolActive):
      return {
        ...state,
        isMeasurementToolActive: !state.isMeasurementToolActive,
      }
    case getType(actions.setIsLargerAspectRatioScreen):
      return {
        ...state,
        isLargerAspectRatioScreen: action.payload,
      }
    case getType(actions.setIsSmallScreen):
      return {
        ...state,
        isSmallScreen: action.payload,
      }
    case getType(actions.setShowDynamicPbl):
      return {
        ...state,
        showDynamicPbl: action.payload,
      }
    case getType(actions.setShouldResetZoom):
      return {
        ...state,
        shouldResetZoom: action.payload,
        scale: 1,
      }
    case getType(actions.setIsEditingPblOnRadiograph):
      return {
        ...state,
        isEditingPblOnRadiograph: action.payload,
      }

    default:
      return state
  }
}
