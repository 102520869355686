import axios from "axios"
import { store } from "core/store/configureStore"
import {
  logOutAction,
  setServerError,
  setServerErrorMessage,
} from "library/common/actions/user"
import { setShouldTakeScreenshot } from "library/common/actions/upload"
import { requestLogin } from "library/services/userApi"
import { OAUTH_PROVIDER_NAME } from "./constants"
import { getSsoProvider, saveOauthResponse } from "./token"
import { LogoutReason } from "library/common/types/userTypes"

export const baseURL = process.env.REACT_APP_API_URL

export const authFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const iFrameRefreshAccessToken = () => {
  return new Promise<string>((resolve, reject) => {
    // If no response in 5 seconds, consider token refresh failed
    const timeout = setTimeout(() => {
      window.removeEventListener("message", onMessage)
      reject()
    }, 5000)

    function onMessage(
      event: MessageEvent<{ eventName: string; data: { token: string } }>
    ) {
      if (event.data?.eventName === "token-updated") {
        clearTimeout(timeout)
        window.removeEventListener("message", onMessage)
        const token = event.data.data.token
        resolve(token)
      }
    }
    window.addEventListener("message", onMessage)
    window.parent.postMessage("token-expired", "*")
  })
}

const wso2RefreshAccessToken = async () => {
  const ssoProvider = getSsoProvider() || OAUTH_PROVIDER_NAME
  if (!ssoProvider) throw new Error("no SSO provider available")

  const refreshToken = sessionStorage.getItem("refresh_token")
  if (!refreshToken) throw new Error("no refresh token available")

  const resp = await requestLogin({
    isSSO: true,
    username: ssoProvider,
    password: refreshToken, // token
    isRefreshToken: true,
  })

  // overwrite the stored token if it exists
  const oldToken = sessionStorage.getItem("access_token")
  oldToken && sessionStorage.setItem("access_token", resp.data.token)
  saveOauthResponse(resp.data.oAuthResponse)
  return resp.data.token
}

const fetch = axios.create({ baseURL })

const hasExpectedData = (data: any) =>
  data &&
  (data.imageWidth ||
    data.imageHeight ||
    data.image ||
    data.newPassword ||
    data.oldPassword)

const hasExpected400Fields = (data: any) =>
  data &&
  // We need to keep showing the respctive modals when receiving either of the errors below
  (data.newPassword || data.oldPassword || data.imageHeight || data.imageWidth)

export const errorHandler = async (error: any) => {
  const status = error.response?.status

  // We prevent showing the report problem modal and rather show the error toast.
  if (!!error.response.data.alfaDocsApiKey) {
    throw error
  }

  if (status === 401 || status === 403) {
    if (status === 401 && !error.config._retry) {
      // Request refresh token if the access token expired
      let refreshedToken = ""
      try {
        refreshedToken =
          window.parent !== window
            ? await iFrameRefreshAccessToken()
            : await wso2RefreshAccessToken()
      } catch {
        console.error("failed to refresh access token")
      }
      if (refreshedToken) {
        error.config._retry = true
        const authorization = `Bearer ${refreshedToken}`
        // set the new token as the default token for future requests from now on
        fetch.defaults.headers.common.Authorization = authorization
        // and set the new token for the retry of the request
        error.config.headers.Authorization = authorization
        return await fetch.request(error.config)
      }
    }

    store.dispatch(logOutAction(LogoutReason.Unauthorized))
  } else if (status === 502 || status === 503) {
    store.dispatch(setServerError("unavailable"))
  } else if (!navigator.onLine || !error.response) {
    store.dispatch(setServerError("disconnect"))
  } else if (
    error.response &&
    (status === 400
      ? !hasExpected400Fields(error.response.data)
      : !hasExpectedData(error.response.data))
  ) {
    // show an error popup when the response doesn't contain a type of error we can handle
    store.dispatch(setServerError(`${status}`))
    store.dispatch(setShouldTakeScreenshot(true))
    store.dispatch(setServerErrorMessage(error.response.data[0]))
  } else if (status === 404) {
    store.dispatch(setServerErrorMessage(error.response.data.error))
  }

  throw error
}

fetch.interceptors.response.use((response) => response, errorHandler)

export default fetch
