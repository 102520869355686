import { Modals } from "../reducers/modalsReducer"

export enum ModalTypes {
  OPEN_MODAL = "@@MODAL/OPEN_MODAL",
  CLOSE_MODAL = "@@MODAL/CLOSE_MODAL",
  SET_NEW_AI_VERSION_MODAL_SHOWN_IDS = "@@MODAL/SET_NEW_AI_VERSION_MODAL_SHOWN_IDS",
}

/*
  Rearrange to change the help modal button order.
  HelpModalEntry enum values to be camelCase as it is used with translations
*/
export enum HelpModalEntry {
  Legend = "legend",
  Analysis = "analysis",
  Navigation = "navigation",
  WhatsNew = "new",
  Languages = "language",
  UserManual = "manual",
  ProductInformation = "info",
  Invisalign = "invisalign",
  ReportProblem = "problem",
}

export type ModalType = {
  openedModal: Modals
  currentFaqTab?: HelpModalEntry
  navigatingUrl?: string
}
