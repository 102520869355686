export enum UserTypes {
  LOGOUT_ACTION = "@@USER/LOGOUT_ACTION",
  LOGIN_ACTION = "@@USER/LOGIN_ACTION",
  LOGIN_ACTION_WITH_DATA = "@@USER/LOGIN_ACTION_WITH_DATA",
  LOGIN_ACTION_SUCCESS = "@@USER/LOGIN_ACTION_SUCCESS",
  LOGIN_ACTION_ERROR = "@@USER/LOGIN_ACTION_ERROR",
  LOGIN_AZUREAD_SUCCESS = "@@USER/LOGIN_AZUREAD_SUCCESS",
  RESET_PASSWORD_ACTION = "@@USER/RESET_PASSWORD_ACTION",
  NEW_PASSWORD_ERROR_ACTION = "@@USER/NEW_PASSWORD_ERROR_ACTION",
  OLD_PASSWORD_ERROR_ACTION = "@@USER/OLD_PASSWORD_ERROR_ACTION",
  SET_MUST_RESET_PASSWORD_ACTION = "@@USER/SET_MUST_RESET_PASSWORD_ACTION",
  RESET_PASSWORD_FIRST_TIME_ACTION = "@@USER/RESET_PASSWORD_FIRST_TIME_ACTION",
  GET_USER_NAME = "@@USER/GET_USER_NAME",
  SET_USER_RESULT_STATUS = "@@USER/SET_USER_RESULT_STATUS",
  SET_USER_INFO = "@@USER/SET_USER_INFO",
  GET_USER_EMAIL = "@@USER/GET_USER_EMAIL",
  SET_USER_EMAIL = "@@USER/SET_USER_EMAIL",
  SET_SERVER_ERROR = "@@USER/SET_SERVER_ERROR",
  SET_SERVER_ERROR_MESSAGE = "@@USER/SET_SERVER_ERROR_MESSAGE",
  RESET_ERROR_ACTION = "@@USER/RESET_ERROR_ACTION",
  SET_HANDLER_NAME = "@@USER/SET_HANDLER_NAME",
  SET_HANDLER_HASH = "@@USER/SET_HANDLER_HASH",
  SUBMIT_HANDLER_HASH = "@@USER/SUBMIT_HANDLER_HASH",
  TOGGLE_CARIES_PRO = "@@USER/TOGGLE_CARIES_PRO",
  GET_BONELOSS_PRO = "@@USER/GET_BONELOSS_PRO",
  TOGGLE_BONELOSS_PRO = "@@USER/TOGGLE_BONELOSS_PRO",
  SET_BONELOSS_PRO = "@@USER/SET_BONELOSS_PRO",
  SET_WHATS_NEW = "@@USER/SET_WHATS_NEW",
  SET_LAST_COACH_MARKS = "@@USER/SET_LAST_COACH_MARKS",
  TOGGLE_DRAWING_MODE = "@@USER/TOGGLE_DRAWING_MODE",
  SET_TOOTH_BASED_PERI = "@@USER/SET_TOOTH_BASED_PERI",
  GENERATE_TOKEN = "@@USER/GENERATE_TOKEN",
  SET_GENERATED_TOKEN = "@@USER/SET_GENERATED_TOKEN",
  SET_IMPERSONATE = "@@USER/SET_IMPERSONATE",
  SET_ALFA_DOCS_API_KEY = "@@USER/SET_ALFA_DOCS_API_KEY",
  SET_USER_INTEGRATION_RESULT_STATUS = "@@USER/SET_USER_INTEGRATION_RESULT_STATUS",
  SET_RESET_PASSWORD_STATUS = "@@USER/SET_RESET_PASSWORD_STATUS",
  SET_IS_OPEN_USER_SETTINGS_MODAL = "@@USER/SET_IS_OPEN_USER_SETTINGS_MODAL",
}

// License enum values to be camelCase as it is used with state objects
export enum License {
  Trial = "trial",
  Invalid = "invalid",
  Valid = "valid",
}

// NumberingSystem enum values to be camelCase as it is used with state objects
export enum NumberingSystem {
  Fdi = "FDI",
  Universal = "universal",
}

export enum Role {
  None = "UNSET",
  InternalTest = "INTERNAL_TEST",
  ExternalTest = "EXTERNAL_TEST",
  ClinicalTrial = "CLINICAL_TRIAL",
  Demo = "DEMO",
}

export interface ContextQuery {
  companyId?: string
  doctorId?: string
  impersonate?: string
}

export enum LogoutReason {
  None = "None",
  Timeout = "Timeout",
  Button = "Button",
  Unauthorized = "Unauthorized",
}
